.hero--section--img {
  display: flex;
  width: 500px;
  height: 500px;
  border-style: dotted;
  border-color: aqua;
}
.hero--section--img > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.icons-div {
  /* margin-top: 10px; */
}
.icons-div div {
  font-size: large;
  font-weight: 200;
  display: flex;
  gap: 10px;
  justify-items: center;
  align-items: center;
  margin-top: 5px;
}
.whatsapp-img-div {
  position: fixed;
  z-index: 1000;
  top: 550px;
  left: 320px;
  transform: translateX(-50%);
  cursor: pointer;
}
.whatsapp-img-size {
  width: 60px;
  height: 60px;
}
.whatsapp-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 768px) {
  .hero--section--img {
    width: 400px;
    height: 400px;
    border-style: none;
  }
  .btn-primary {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 50px; */
  }
}
@media screen and (max-width: 480px) {
  .hero--section--img {
    margin-top: 120px;
    width: 350px;
    height: 350px;
  }
  .whatsapp-img-div {
    position: fixed;
    z-index: 1000;
    top: 480px;
    left: 320px;
    transform: translateX(-50%);
    cursor: pointer;
  }
}
